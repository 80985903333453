import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import Auth, { Group } from '../../components/Auth';
import SEO from '../../components/SEO';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from "gatsby-plugin-firebase";

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [user] = useAuthState(typeof window !== 'undefined' ? firebase.auth() : {});

  const signInWithEmailAndPasswordHandler = 
    (event, email, password) => {
        event.preventDefault();
        firebase.auth().signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // Signed in
          console.log('signed-in')
        })
        .catch((error) => {
          var errorCode = error.code;
          setError(error.message);
        });
  };

  if(!user) {
    return (
      <Auth title="Login" subTitle="Hello! Login with your email">
        <SEO title="Login" />
        {error !== null && <div className = "py-4 bg-red-600 w-full text-white text-center mb-3">{error}</div>}
        <form>
          <InputGroup fullWidth>
            <input type="email" placeholder="Email Address" onChange={(evt) => {setEmail(evt.target.value)}} />
          </InputGroup>
          <InputGroup fullWidth>
            <input type="password" placeholder="Password" onChange={(evt) => {setPassword(evt.target.value)}} />
          </InputGroup>
          <Button status="Success" type="button" shape="SemiRound" fullWidth onClick = {(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}>
            Login
          </Button>
        </form>
      </Auth>
    );
  }

  if(user) {
    navigate('/dashboard');
  }

  return <div />;
}
